import { Component, OnInit, Input } from '@angular/core';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-header-register',
  templateUrl: './header-register.component.html',
  styleUrls: ['./header-register.component.css']
})
export class HeaderRegisterComponent implements OnInit {
  util:Util = new  Util();

  @Input()
  isWhite:boolean
  
  constructor() { }

  ngOnInit() {
  }

  changepage() {
    window.location.href = Util.urlLandinga;
  }
}
