import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resposta-comentario',
  templateUrl: './resposta-comentario.component.html',
  styleUrls: ['./resposta-comentario.component.css']
})
export class RespostaComentarioComponent implements OnInit {
  @Input()
  comentario: any = {
    usuario: {}
  };

  constructor(private router: Router) {}

  ngOnInit() {}

  get userImage() {
    return this.comentario.usuario.logo
      ? this.comentario.usuario.logo
      : '../../../../assets/images/default-user.png';
  }

  formatData(data) {
    if (data) {
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return '';
  }

  goToUser() {
    const id = this.comentario.usuario.idUsuarios;
    this.router.navigateByUrl(`/dash/user-profile/${id}`);
  }
}
