import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Util } from 'src/app/util';

@Injectable({
  providedIn: 'root'
})
export class ReceitasService {
  constructor(private http: HttpClient) {}

  public getMinhasReceita(idUsuario, idPerfil): Observable<any> {
    return this.http.get(
      `${Util.url}/api/receitas/myReceitasApp/${idUsuario}/${idPerfil}`
    );
  }

  public getMyFavoritos(idUsuario): Observable<any> {
    return this.http.get(`${Util.url}/api/favoritos/myFavoritos/${idUsuario}`);
  }

  public getById(idReceita, idUsuario): Observable<any> {
    return this.http.get(
      `${Util.url}/api/receitas/findByIdApp/${idReceita}/${idUsuario}`
    );
  }

  public favoritar(idReceita, idUsuario): Observable<any> {
    return this.http.get(
      `${Util.url}/api/favoritos/favoritar/${idReceita}/${idUsuario}`
    );
  }

  public like(idReceita, idUsuario): Observable<any> {
    return this.http.get(
      `${Util.url}/api/receitas/like/${idReceita}/${idUsuario}`
    );
  }

  public createReceita(body): Observable<any> {
    return this.http.post(`${Util.url}/api/receitas/new`, body);
  }

  public salvaNovosIngredientes(body): Observable<any> {
    return this.http.post(`${Util.url}/api/receitas/salvaingredientes`, body);
  }

  public getByName(body): Observable<any> {
    return this.http.post(`${Util.url}/api/receitas/pesquisa`, body);
  }

  public reportaErro(body): Observable<any> {
    return this.http.post(`${Util.url}/api/receitas/reportaerro`, body);
  }

  public updateReceita(body): Observable<any> {
    return this.http.put(`${Util.url}/api/receitas/update`, body);
  }


  
  public getReceitasMaisCurtidas(idUsuario): Observable<any> {
    return this.http.get(
      `${Util.url}/api/receitas/getreceitascurtidas?idUsuario=${idUsuario}`
    );
  }

  public getReceitasChefsCaseirosMaisPopulares(idUsuario): Observable<any> {
    return this.http.get(
      `${Util.url}/api/receitas/getreceitascurtidasusers?idUsuario=${idUsuario}`
    );
  }

  public pesquisar(body): Observable<any> {
    return this.http.post(`${Util.url}/api/receitas/pesquisa`, body);
  }
}
