import { Component, OnInit, ElementRef, OnDestroy, Input } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-dash-layout',
  templateUrl: './dash-layout.component.html',
  styleUrls: ['./dash-layout.component.less']
})
export class DashLayoutComponent implements OnInit {

  constructor(private element: ElementRef) {}

  ngOnInit() {}

  openNav() {
    document.getElementById('mySidenav').style.width = '250px';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }
}
