import { Routes } from "@angular/router";


import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { WelcomeLayoutComponent } from "./layouts/welcome/welcome-layout.component";
import { DashLayoutComponent } from "./layouts/dash/dash-layout.component";
import {AuthGuard} from './auth/auth.guard';

export const AppRoutes: Routes = [
 
   {
      path: "",
      redirectTo: "auth/login",
      pathMatch: "full"
    }, 

  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "auth",
        loadChildren: "./auth/auth.module#AuthModule"
      },
       {
        path: "login",
        redirectTo: "auth/login",
        pathMatch: "full"
      },  
      {
        path: "register",
        redirectTo: "auth/register",
        pathMatch: "full"
      },
      {
        path: "forgot-pass",
        redirectTo: "auth/forgot-pass",
        pathMatch: "full"
      }
    ]
  },
  {
    path: "",
    component: WelcomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "welcome",
        loadChildren: "./welcome/welcome.module#WelcomeModule"
      },
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full"
      },     
      {
        path: "choices",
        redirectTo: "welcome/choices",
        pathMatch: "full"
      },  
      {
        path: "plates",
        redirectTo: "welcome/plates",
        pathMatch: "full"
      }, 
       {
        path: "preferences",
        redirectTo: "welcome/preferences",
        pathMatch: "full"
      }, 
    ]
  },
  { 
    path: "",
    component: DashLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dash",
        loadChildren: "./dash/dash.module#DashModule"
      },
      {
        path: "home",
        redirectTo: "dash/home",
        pathMatch: "full"
      },
      {
        path: "my-playlists",
        redirectTo: "dash/my-playlists",
        pathMatch: "full"
      },
      {
        path: "playlist",
        redirectTo: "dash/playlist",
        pathMatch: "full"
      },
      {
        path: "my-recipes",
        redirectTo: "dash/my-recipes",
        pathMatch: "full"
      },
      {
        path: "register-full",
        redirectTo: "dash/register-full",
        pathMatch: "full"
      }
    ]
  },
  
  
  
];
