import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";

declare const $: any;

@Component({
  selector: 'app-welcome-layout',
  templateUrl: './welcome-layout.component.html',
  styleUrls: ['./welcome-layout.component.less']
})

export class WelcomeLayoutComponent implements OnInit {
    constructor(private element: ElementRef) {
    
  }

  ngOnInit() {
    
  }
}
