/*
  Adaptação do código disponível em:
  https://www.bootply.com/QhA0MPMKtI
*/

import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-horizontal-slider',
  templateUrl: './horizontal-slider.component.html',
  styleUrls: ['./horizontal-slider.component.css']
})
export class HorizontalSliderComponent implements OnInit {
  eventSubscriber: Subscription;

  constructor(private router: Router) {}

  ngOnInit() {}
}
