import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Util } from 'src/app/util';

@Injectable({
  providedIn: 'root'
})
export class ComentarioService {
  constructor(private http: HttpClient) {}

  public like(idComentario, idUsuario): Observable<any> {
    return this.http.get(
      `${Util.url}/api/comentarios/like/${idComentario}/${idUsuario}`
    );
  }

  public createComentario(body): Observable<any> {
    return this.http.post(`${Util.url}/api/comentarios/save`, body);
  }

  public createResposta(body): Observable<any> {
    return this.http.post(`${Util.url}/api/comentarios/resposta`, body);
  }
}
