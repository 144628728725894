import { Component, OnInit, Input } from '@angular/core';
import { Util } from 'src/app/util';
import { ReceitasService } from '../../services/receitas.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-receita-usuario',
  templateUrl: './card-receita-usuario.component.html',
  styleUrls: ['./card-receita-usuario.component.css']
})
export class CardReceitaUsuarioComponent implements OnInit {
  @Input()
  large: boolean = false;
  @Input()
  noUser: boolean = false;
  @Input()
  receita: any;

  @Input()
  troca: boolean;

  @Input()
  cardapio: any;


  @Input()
  nome:any;


  constructor(
    private receitaService: ReceitasService,
    private router: Router
  ) {}

  ngOnInit() {}

  getImage(images) {
    if (images && images[0]) {
      if (images[0].urlImage) {
        return images[0].urlImage;
      } else if (Util.isURL(images[0].imageB64)) {
        return images[0].imageB64;
      } else {
        return `data:image/jpeg;base64,${images[0].imageB64}`;
      }
    }
    return 'https://cdn11.bigcommerce.com/s-auu4kfi2d9/stencil/59606710-d544-0136-1d6e-61fd63e82e44/e/74686f40-d544-0136-c2c6-0df18b975cb0/icons/icon-no-image.svg';
  }

  curtir() {
    const idUsuario = JSON.parse(localStorage.getItem('currentUser'))
      .idUsuarios;
    const id = this.receita.idReceitas;

    this.receitaService.like(id, idUsuario).subscribe(data => {
      this.receita.curtido = data.curtido;
      this.receita.like = data.likes;
    });
  }

  goToReceita() {
    console.log(this.troca);
    const id = this.receita.idReceitas;
    if(this.troca){
      this.router.navigateByUrl(`/dash/recipe/${id}?troca=${this.troca}&cardapio=${this.cardapio}&nome=${this.nome}`);
    }else{
      this.router.navigateByUrl(`/dash/recipe/${id}`);
    }

  }

  get userImage() {
    return this.receita.usuario.logo
      ? this.receita.usuario.logo
      : '../../../../assets/images/default-user.png';
  }

  goToUser() {
    const id = this.receita.usuario.idUsuarios;
    console.log(this.receita);
    this.router.navigateByUrl(`/dash/user-profile/${id}`);
  }
}
