import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";

declare var $: any;


@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html',
   styleUrls: ['./auth-layout.component.less']
})
export class AuthLayoutComponent implements OnInit {

   constructor(private element: ElementRef) {
    
  }

  ngOnInit() {
    
  }
 
}