import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import PubSub from 'pubsub-js';
import { ProdutosService } from '../../services/produtos.service';
import { TagsService } from '../../services/tags.service';
import { Util } from 'src/app/util';
import {UsuarioService} from '../../services/usuario.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  token = '';
  logo = '';
 troca = false;
  stringBusca = '';

  tempoPreparoInicial = 5;
  tempoPreparoFinal = 120;

  writedName: string = '';
  foodList = [];
  selected = [];
user:any = {};
  categoriaTags: any = [];
mensal = false;
  selectedTagsByCategoria: any = {};
  selectedTags: any = [];

  categoriaTagSelected: number = -1;
  emailAmigo: any;

  constructor(
    private router: Router,
    private produtosService: ProdutosService,
    private tagsService: TagsService,
    private usuarioService:UsuarioService
  ) {
    this.logo = JSON.parse(localStorage.getItem('currentUser')).logo;
    this.user =  JSON.parse(localStorage.getItem('usuarioPai'));
    console.log(this.user);
    if(this.user == null || this.user.assinaturaUser == null || this.user.assinaturaUser.idAssinatura == 5){
      this.mensal  = true;
    }
    console.log(this.user);
    this.token = PubSub.subscribe('UPDATE-PROFILE', () => {
      this.logo = JSON.parse(localStorage.getItem('currentUser')).logo;
    });

    this.tagsService.getAllCategoriaTags().subscribe(data => {
      this.categoriaTags = data;
      console.log(this.categoriaTags);
    });
  }

  ngOnInit() {
    $('#tempoRange')
      .slider({
        id: 'sliderTempo',
        min: 15,
        max: 120,

        range: true,
        value: [15, 120]
      })
      .on('slide', ev => {
        this.tempoPreparoInicial = ev.value[0];
        this.tempoPreparoFinal = ev.value[1];
      });
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

  search(e) {
    const value = e.target.value;
    //Busca caso o valor digitado não tenha sido selecinado do datalist
    if (value && !this.foodList.find(food => food.nome === value)) {
      this.produtosService.getProdutosByName(value).subscribe(data => {
        this.foodList = data;
      });
    }
  }

  add(): void {
    //Busca na lista de alimentos do back
    const food = this.foodList.find(food => food.nome === this.writedName);

    if (food) {
      //Valida se já foi selecionado antes
      if (
        !this.selected.find(
          foodSelected => foodSelected.idProdutos === food.idProdutos
        )
      ) {
        this.selected.push(food);
      }
      this.writedName = '';
    }
  }

  remove(index): void {
    this.selected.splice(index, 1);
  }

  compareTags(o1: any, o2: any): boolean {
    return o1.tag === o2.tag;
  }

  selectTag(categoria, newTag, multiple = true) {
    const tagsByCategoria = this.selectedTagsByCategoria[categoria];
    //Verifica se já existe alguma tag nessa categoria
    if (tagsByCategoria) {
      //Se for multíplo select, valida se já está selecionado, caso não add no array
      if (multiple) {
        const index = tagsByCategoria.findIndex(
          tag => newTag.idTags === tag.idTags
        );

        if (index === -1) {
          tagsByCategoria.push(newTag);
        } else {
          tagsByCategoria.splice(index, 1);
        }
      } else {
        //Se não for multiplo, altera apenas o primeiro item do array
        tagsByCategoria[0] = newTag;
      }
    } else {
      this.selectedTagsByCategoria[categoria] = [];
      this.selectedTagsByCategoria[categoria].push(newTag);
    }

    this.updateSelectedTag();
  }

  updateSelectedTag() {
    let aux = [];
    Object.keys(this.selectedTagsByCategoria).forEach(key => {
      if (this.selectedTagsByCategoria[key]) {
        aux = [...aux, ...this.selectedTagsByCategoria[key]];
      }
    });
    this.selectedTags = aux;
  }

  isSelected(tag) {
    return this.selectedTags.findIndex(t => tag.idTags === t.idTags) !== -1;
  }

  setTroca(){
    this.troca = true;
  }

  filtrar() {
    console.log($("#troca").data("troca") );
    if($("#troca").data("troca") != undefined){
      this.setTroca();
    }
    console.log($("#troca").data("cardapio"));
    console.log($("#troca").data("nome"));
    console.log(this.troca);
    let cardapio = $("#troca").data("cardapio");
    let nome =  $("#troca").data("nome");
    console.log(nome);
    const tempoPreparoInicial = this.tempoPreparoInicial;
    const tempoPreparoFinal = this.tempoPreparoFinal;
    const listaIngredientes = this.selected.map(item => item.nome);
    const listaTags = this.selectedTags.map(item => item.idTags);
    const stringBusca = this.stringBusca;

    this.stringBusca = '';
    this.selectedTags = [];
    this.selected = [];
    if(this.troca){
      this.router.navigate(['/dash/search'], {
        queryParams: {
          tempoPreparoInicial,
          tempoPreparoFinal,
          stringBusca,
          listaIngredientes: listaIngredientes.join(),
          listaTags: listaTags.join(),
          troca:true,
          cardapio:cardapio,
          nome:nome
        }
      });
    }else{
      this.router.navigate(['/dash/search'], {
        queryParams: {
          tempoPreparoInicial,
          tempoPreparoFinal,
          stringBusca,
          listaIngredientes: listaIngredientes.join(),
          listaTags: listaTags.join()
        }
      });
    }

  }

  teste() {
    console.log(this.categoriaTagSelected);
  }

  indique() {
    this.usuarioService.indique(this.user.idUsuarios,this.emailAmigo).subscribe(value =>
      {}
    );
  }
}
