import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Util } from 'src/app/util';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProdutosService {
  constructor(private http: HttpClient) {}

  public getProdutosByName(name): Observable<any> {
    return this.http.get(`${Util.url}/api/produtos/filter/${name}`);
  }
}
