import { Injectable, NgModule } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    let token = localStorage.getItem('token');
    if (token) {
      const cloneRequest = request.clone({
        setHeaders: {
          Authorization: `Basic ${token}`
        }
      });
      return next.handle(cloneRequest);
    }

    return next.handle(request);
  }
}