import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Util } from 'src/app/util';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  constructor(private http: HttpClient) {}

  createUser(user): Observable<any> {
    return this.http.post(`${Util.url}/api/usuarios/new`, user);
  }

  updateUser(user): Observable<any> {
    return this.http.put(`${Util.url}/api/usuarios/update`, user);
  }

  getRecomendacoesReceitas(idUsuario): Observable<any> {
    return this.http.get(`${Util.url}/api/receitas/recomendadas/${idUsuario}`);
  }

  getChefsCaseirosPopulares(idUsuario): Observable<any> {
    return this.http.get(`${Util.url}/api/receitas/getreceitascurtidasusers`, { params: {
        idUsuario: idUsuario
      }});
  }
  getByName(idUsuario, name): Observable<any> {
    console.log("nome = "+name);
    return this.http.get(
      `${
        Util.url
      }/api/usuarios/pesquisa?idUsuario=${idUsuario}&paginaAtual=1&quantidadeItensPorPagina=100&stringBusca=${name}`
    );
  }

  getById(idUsuario): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/findById/${idUsuario}`);
  }

  follow(body): Observable<any> {
    return this.http.post(`${Util.url}/api/usuarios/follow`, body, {
      responseType: 'text'
    });
  }

  unfollow(body): Observable<any> {
    return this.http.post(`${Util.url}/api/usuarios/unfollow`, body, {
      responseType: 'text'
    });
  }

  getPerfis(idUsuario): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/getperfis/${idUsuario}`);
  }

  getProfile(idUsuario , idUsuario2): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/getperfiluser`,{ params: {
        idUsuario: idUsuario, idUsuarioPerfil:idUsuario2
      }});
  }

  deletaPerfil(idUsuario): Observable<any> {
    return this.http.delete(`${Util.url}/api/usuarios/perfil/delete/${idUsuario}`);
  }

  addPerfil(body): Observable<any> {
    return this.http.post(`${Util.url}/api/usuarios/newperfil`, body, {
    
    });
  }

  getPontuacao(idUsuario): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/getpontuacao`,{ params: {
      idUsuario: idUsuario
    }});
  }

  getIdSuperLogica(email): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/getidsuperlogica`,{ params: {
        emailUsuario: email
      }});
  }
  getAssinaturaUsuario(id): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/getassinaturausuario`,{ params: {
        idUsuario: id
      }});
  }

  indique(idUsuarios, emailAmigo): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/indiqueamigo`,{ params: {
        idUsuario: idUsuarios,emailUser:emailAmigo
      }});
  }

  indiqueReceita(idUsuarios,nomeReceita, emailAmigo): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/indiqueamigo`,{ params: {
        idUsuario: idUsuarios,emailUser:emailAmigo,nomeReceita:nomeReceita
      }});
  }
  confirma(codigo): Observable<any> {
    return this.http.get(`${Util.url}/api/usuarios/confirma/${codigo}`);
  }
}
