import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';

import {
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatSelectModule,
  MatListModule,
  MatSidenavModule,
  MatGridListModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';
import { AvatarModule } from 'ngx-avatar';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ComentarioComponent } from './components/comentario/comentario.component';
import { RespostaComentarioComponent } from './components/resposta-comentario/resposta-comentario.component';
import { HorizontalSliderComponent } from './components/horizontal-slider/horizontal-slider.component';
import { ReceitaCardHomeComponent } from './components/receita-card-home/receita-card-home.component';
import { CardReceitaUsuarioComponent } from './components/card-receita-usuario/card-receita-usuario.component';
import { HeaderRegisterComponent } from './components/header-register/header-register.component';

const materialModules = [
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatSelectModule,
  MatListModule,
  MatSidenavModule,
  MatGridListModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule
];

const imp = [
  ...materialModules,
  CommonModule,
  RouterModule,
  AvatarModule,
  CurrencyMaskModule,
  ReactiveFormsModule,
  FormsModule,
  HttpClientModule
];
const dec = [
  HeaderComponent,
  ComentarioComponent,
  RespostaComentarioComponent,
  HorizontalSliderComponent,
  ReceitaCardHomeComponent,
  CardReceitaUsuarioComponent,
  HeaderRegisterComponent
];

@NgModule({
  imports: [...imp],
  declarations: [...dec],
  exports: [...imp, ...dec]
})
export class SharedModule {}
