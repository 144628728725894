import { Component, OnInit, Input } from '@angular/core';

import * as moment from 'moment';
import { ReceitasService } from '../../services/receitas.service';
import { ComentarioService } from '../../services/comentario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comentario',
  templateUrl: './comentario.component.html',
  styleUrls: ['./comentario.component.css']
})
export class ComentarioComponent implements OnInit {
  @Input()
  comentario: any = {
    usuario: {}
  };

  inputIsOpen: boolean = false;
  answersIsOpen: boolean = false;

  textoResposta: string = '';

  constructor(
    private comentarioService: ComentarioService,
    private router: Router
  ) {}

  ngOnInit() {}

  get userImage() {
    return this.comentario.usuario.logo
      ? this.comentario.usuario.logo
      : '../../../../assets/images/default-user.png';
  }

  formatData(data) {
    if (data) {
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return '';
  }

  like() {
    const usuario = JSON.parse(localStorage.getItem('currentUser'));
    this.comentarioService
      .like(this.comentario.idComentarios, usuario.idUsuarios)
      .subscribe(data => {
        this.comentario.curtido = data.curtido;
        this.comentario.likes = data.likes;
      });
  }

  enviarResposta() {
    const texto = this.textoResposta;
    const usuario = JSON.parse(localStorage.getItem('currentUser'));

    if (texto) {
      this.comentarioService
        .createResposta({
          idComentario: this.comentario.idComentarios,
          resposta: texto,
          usuario: usuario.idUsuarios
        })
        .subscribe(data => {
          this.comentario.resposta = [data, ...this.comentario.resposta];
          this.textoResposta = '';
          this.answersIsOpen = true;
          this.inputIsOpen = false;
        });
    }
  }

  goToUser() {
    const id = this.comentario.usuario.idUsuarios;
    this.router.navigateByUrl(`/dash/user-profile/${id}`);
  }
}
